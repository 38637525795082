// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  authority: "https://signin.dev-connect.aveva.com",
  clientId: "pB6IhY7XKspoGkpU71ZqZY9JgRroTsvA",
  singleSignOutUrl: "https://profile.capdev-connect.aveva.com/singlesignout",
  connect: "https://capdev-connect.aveva.com",
  apis: {
    am: "https://api.capdev-connect.aveva.com/am",
    registry: "https://api.capdev-connect.aveva.com/registry",
    uam: "https://services.capdev-connect.aveva.com/uam/v2",
    scm: "https://api.capdev-connect.aveva.com/scm",
    logging: "https://api.capdev-connect.aveva.com/logging",
    assets: "https://api.capdev-connect.aveva.com/assets",
  },
  spectrum: {
    account: "37764c2a-bbac-4c0e-ae26-8524c2da8c9c",
    solution: "6f297240-8606-467b-ad5b-4ebe6320fbd3",
    dataSourceUrl: "https://services.capdev-connect.aveva.com/spectrum",
    stage: "dev",
  },
};
