import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthenticationService } from "@aveva/connect-web-core";
import { from, Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { InternalUseDialogComponent } from "../../dialogs/internal-use-dialog/internal-use-dialog.component";

@Injectable()
export class TestRunsGuard implements CanActivate {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return from(this.authService.getUser()).pipe(
      mergeMap((user) => {
        if (!user) {
          this.router.navigate(["/login"], {
            replaceUrl: true,
            queryParams: {
              account_hint: environment.spectrum.account,
              solution_hint: environment.spectrum.solution,
            },
          });
          return of(false);
        } else if (user.account !== environment.spectrum.account) {
          return this.dialog
            .open(InternalUseDialogComponent)
            .afterClosed()
            .pipe(
              mergeMap(() => from(this.authService.signout())),
              map(() => false)
            );
        } else {
          return of(true);
        }
      })
    );
  }
}
