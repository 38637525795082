import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ErrorHandlerService, AuthenticationService, ConnectApiClientService, Account } from "@aveva/connect-web-core";

@Injectable()
export class AccountResolverService implements Resolve<Promise<Account | null>> {
  constructor(
    private errorHandler: ErrorHandlerService,
    private authService: AuthenticationService,
    private connectApiClient: ConnectApiClientService
  ) {}

  async resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    try {
      const user = await this.authService.getUser();
      const account = await this.connectApiClient.accountManagement.getAccount(user.account);
      return account;
    } catch (error) {
      this.errorHandler.handle(error);
      return null;
    }
  }
}
