import { NgModule } from "@angular/core";
import { ConnectCoreModule } from "@aveva/connect-web-core";
import { SpectrumComponent } from "./spectrum.component";
import { SpectrumRoutingModule } from "./spectrum-routing.module";
import { AccountResolverService } from "../resolvers/account-resolver.service";
import { TestRunsGuard } from "./test-runs/test-runs.guard";

@NgModule({
  declarations: [SpectrumComponent],
  imports: [ConnectCoreModule, SpectrumRoutingModule],
  providers: [TestRunsGuard, AccountResolverService],
})
export class SpectrumModule {}
