<section class="test-runs-content">
  <form [formGroup]="createRunForm" (ngSubmit)="createRun()">
    <mat-form-field>
      <mat-label for="name">Test Run Name:</mat-label>
      <input matInput type="text" formControlName="name"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label for="repetitions">Number of test runs:</mat-label>
      <input matInput type="number" formControlName="repetitions"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label for="maxConcurrency">Max runs to perform in parallel:</mat-label>
      <input matInput type="number" formControlName="maxConcurrency"/>
    </mat-form-field>

    <mat-form-field>
      <mat-label for="testPlan">Test plan:</mat-label>
      <mat-select formControlName="testPlan">
        <mat-option *ngFor="let testPlan of testPlans | keyvalue" [value]="testPlan.key">{{ testPlan.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="margin-right: 10px">
      <mat-label for="targetSite">Region:</mat-label>
      <mat-select formControlName="targetSite">
        <mat-option *ngFor="let target of targetSites" [value]="target">{{ target.targetRegion | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div style="display: inline-block">
      <button mat-raised-button color="primary" type="submit" [disabled]="createRunForm.invalid || isSaving">
        <mat-icon *appBusy="isSaving">queue</mat-icon>
        Queue
      </button>

      <button mat-raised-button type="button" (click)="reloadRuns()" [disabled]="reloading">
        <mat-icon *appBusy="reloading">replay</mat-icon>
        Reload
      </button>
    </div>
  </form>

  <dl *ngIf="createRunForm.value.targetSite">
    <ng-container *ngIf="createRunForm.value.targetSite.status == 'ok'; else targetSiteStatus">
      <dt>Edge Connector Name:</dt>
      <dd>{{ createRunForm.value.targetSite.name }}</dd>
      <dt>Created At:</dt>
      <dd>{{ createRunForm.value.targetSite.createdAt }}</dd>
    </ng-container>
    <ng-template #targetSiteStatus>
      <dt>Status:</dt>
      <dd>{{ renderSelectedTargetSite() }}</dd>
    </ng-template>
  </dl>

  <form [formGroup]="filterForm">
    <mat-form-field>
      <mat-label>Region</mat-label>
      <mat-select formControlName="region" multiple>
        <mat-select-trigger>
          <span *ngIf="filterForm.value.region?.length == availableRegions.length;then allRegions else someRegions"></span>
          <ng-template #allRegions>All</ng-template>
          <ng-template #someRegions>
            {{filterForm.value.region?.[0] || '' | titlecase }} (+{{ (filterForm.value.region?.length || 0) - 1 }} {{ filterForm.value.region?.length === 2 ? "other" : "others" | titlecase }})
          </ng-template>
        </mat-select-trigger>
        <mat-option *ngFor="let region of availableRegions" [value]="region">{{ region | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>State</mat-label>
      <mat-select formControlName="state" multiple>
        <mat-select-trigger>
          <span *ngIf="filterForm.value.state?.length == availableStates.length;then allStates else someStates"></span>
          <ng-template #allStates>All</ng-template>
          <ng-template #someStates>
            {{filterForm.value.state?.[0] || '' | titlecase }} (+{{ (filterForm.value.state?.length || 0) - 1 }} {{ filterForm.value.state?.length === 2 ? "other" : "others" | titlecase }})
          </ng-template>
        </mat-select-trigger>
        <mat-option *ngFor="let state of availableStates" [value]="state">{{ state | titlecase }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox formControlName="failureOnly">Show failure only</mat-checkbox>
  </form>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let run">{{ run.name }}</td>
    </ng-container>

    <ng-container matColumnDef="targetRegion">
      <th mat-header-cell *matHeaderCellDef>Region</th>
      <td mat-cell *matCellDef="let run">{{ run.targetRegion }}</td>
    </ng-container>

    <ng-container matColumnDef="testPlan">
      <th mat-header-cell *matHeaderCellDef>Test Plan</th>
      <td mat-cell *matCellDef="let run">{{ run.testPlan }}</td>
    </ng-container>

    <ng-container matColumnDef="enqueuedTime">
      <th mat-header-cell *matHeaderCellDef>Enqueued At</th>
      <td mat-cell *matCellDef="let run">
        {{ run.enqueuedTime | date: "yyyy-MM-dd HH:mm:ss":"UTC" }}
        <button
          mat-icon-button
          matRipple
          (click)="setClipboardAndConfirm(run.enqueuedTime, tooltip)"
          #tooltip="matTooltip"
          matTooltip="Copied!"
          matTooltipDisabled="true"
          matTooltipHideDelay="2000"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef>Started At</th>
      <td mat-cell *matCellDef="let run">{{ run.startTime | date: "yyyy-MM-dd HH:mm:ss":"UTC" }}</td>
    </ng-container>

    <ng-container matColumnDef="completedTime">
      <th mat-header-cell *matHeaderCellDef>Completed At</th>
      <td mat-cell *matCellDef="let run">{{ run.completedTime | date: "yyyy-MM-dd HH:mm:ss":"UTC" }}</td>
    </ng-container>

    <ng-container matColumnDef="run">
      <th mat-header-cell *matHeaderCellDef>Run</th>
      <td mat-cell *matCellDef="let run">R:{{ run.repetitions }} / C:{{ run.maxConcurrency }}</td>
    </ng-container>

    <ng-container matColumnDef="maximumDuration">
      <th mat-header-cell *matHeaderCellDef>Max Duration</th>
      <td mat-cell *matCellDef="let run">{{ run.performanceReport?.maximumDuration.toFixed(2) }}</td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let run">{{ run.state | titlecase }}</td>
    </ng-container>

    <ng-container matColumnDef="success">
      <th mat-header-cell *matHeaderCellDef>Success</th>
      <td mat-cell *matCellDef="let run">
        <div [ngSwitch]="run.resultsSummary?.allSucceeded ?? null">
          <div *ngSwitchCase="true" class="div-ok">OK</div>
          <div *ngSwitchCase="false" class="div-failed">Failed</div>
          <div *ngSwitchDefault style="text-align: center">-</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="json">
      <th mat-header-cell *matHeaderCellDef>JSON</th>
      <td mat-cell *matCellDef="let run">
        <button
          mat-icon-button
          matRipple
          (click)="setClipboardJsonAndConfirm(run, tooltip)"
          #tooltip="matTooltip"
          matTooltip="Copied!"
          matTooltipDisabled="true"
          matTooltipHideDelay="2000"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data available
      </td>
    </tr>
  </table>
</section>