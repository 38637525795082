import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Data } from "@angular/router";
import { Account, AuthenticatedUser, SideNavLink } from "@aveva/connect-web-core";
import { Subscription, catchError, of, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";

interface RouteData {
  user: AuthenticatedUser;
  account: Account;
}

@Component({
  selector: "app-spectrum",
  templateUrl: "./spectrum.component.html",
  styleUrl: "./spectrum.component.scss",
})
export class SpectrumComponent implements OnInit, OnDestroy {
  user?: AuthenticatedUser;
  account?: Account;
  accountIcon?: string;

  links: SideNavLink[] = [
    {
      label: "Test Runs",
      icon: "content--list",
      routerLink: ["#/testRuns"],
    },
  ];

  private subscription!: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly http: HttpClient
  ) {}

  ngOnInit(): void {
    this.subscription = this.route.data
      .pipe(
        switchMap((data) => {
          if (!this.isRouteData(data)) {
            return of<void>();
          }

          this.user = data.user;
          this.account = data.account;

          return this.http.get(`${environment.apis.am}/accounts/${data.account.id}/icon`).pipe(
            tap((icon) => {
              if (icon) {
                this.accountIcon = `data:image/png;base64,${icon}`;
              }
            }),
            catchError((err) => {
              console.error(err);
              return of<void>();
            })
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private isRouteData(data: Data | RouteData): data is RouteData {
    return "account" in data && "user" in data;
  }
}
