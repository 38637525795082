import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SpectrumComponent } from "./spectrum.component";
import { AuthenticatedUserResolverService } from "@aveva/connect-web-core";
import { AccountResolverService } from "../resolvers/account-resolver.service";
import { TestRunsGuard } from "./test-runs/test-runs.guard";
import { TestRunsComponent } from "./test-runs/test-runs.component";

const routes: Routes = [
  {
    path: "",
    component: SpectrumComponent,
    resolve: {
      user: AuthenticatedUserResolverService,
      account: AccountResolverService,
    },
    children: [
      {
        path: "",
        redirectTo: "testRuns",
        pathMatch: "full",
      },
      {
        path: "testRuns",
        component: TestRunsComponent,
        canActivate: [TestRunsGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpectrumRoutingModule {}
