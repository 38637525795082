import { ComponentFactoryResolver, Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

/**
 * This directive swaps the attached template for a spinner based upon some expression
 * @example `<mat-icon *appBusy="isLoading"></mat-icon>`
 */
@Directive({
  selector: "[appBusy]",
  standalone: true,
})
export class BusyDirective {
  private isSpinning: boolean | null | undefined = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appBusy(condition: boolean | null | undefined) {
    if (!!condition === this.isSpinning) {
      return;
    }

    this.viewContainer.clear();
    this.isSpinning = condition;

    if (!condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.addSpinner();
    }
  }

  private addSpinner() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    const { instance } = this.viewContainer.createComponent<MatProgressSpinner>(componentFactory);
    instance.diameter = 18;
    instance.color = "accent";
    instance.mode = "indeterminate";
    instance._elementRef.nativeElement.classList.add("spin-on-instance");
  }
}
