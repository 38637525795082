<div class="confirm-warning" *ngIf="data.warning">
  <mat-icon>warning_outline</mat-icon>
  <span>{{ data.warning }}</span>
</div>
<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close="true" color="primary">Confirm</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
